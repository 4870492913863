import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import crypto from 'crypto';
import './App.css';
import _ from 'lodash';

import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCircle } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import questionTypes from './templates/Survey/data/avatarQuestionTypes';
import Layout from './templates/Layout/Layout';
import Index from './pages/Index/Index';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import LoadingPage from './templates/UI/LoadingPage/LoadingPage';
import Contact from './pages/Contact/Contact';

import ReactGa from "react-ga4"

//fab imports all of the brand icons. You use it differently than other icons. example: icon={['fab','apple']}
library.add(faSpinner, fab, faCircle);

class App extends Component {
  
  state = { 
    dataLoaded: false,
    error: false
  }

  componentDidMount(){
    // This is where we will want to make a request to the API to fetch the site information for state.propertyId
    // We must specify this before building the project files.
    const propertyId = 3;

    // Get clickID from url param. If none, check if there's one in cookie.
    const cookies = new Cookies();

    let urlParams = queryString.parse(this.props.location.search);
    let clickId = 0;
    if (urlParams.mbi){
      clickId = encodeURIComponent(parseInt(urlParams.mbi));
      // Set this clickID in a cookie
      cookies.set('mbi', clickId, { secure:true, maxAge: new Date(Date.now() + 30 * 60 * 1000)});
    } else if (cookies.get('mbi')){
      clickId = parseInt(cookies.get('mbi'));
    }

    // If the pushnami override is set, we do NOT want pushnami at all, regardless of the property setting.
    let pushnamiOverride = parseInt(urlParams.psh) === 1 ? true: false;

    // Get clientID avtc from cookie. If none, generate a new one
    let avtc = 0;
    if (cookies.get('avtc')){
      avtc = cookies.get('avtc')
    } else {
      // Generate a new one and set it as a cookie
      const rand = `${Math.random().toString(36).substring(2, 15)}-${Math.random().toString(36).substring(2, 3)}`;
      const binaryEncryptionKey = Buffer.from(process.env.REACT_APP_KEY, "base64");
      const binaryIV = Buffer.alloc(0);
      const cipher = crypto.createCipheriv("AES-128-ECB", binaryEncryptionKey, binaryIV);
      avtc = cipher.update(rand, "utf8", "hex") + cipher.final("hex");
      cookies.set('avtc', avtc, { secure:true, maxAge: new Date(Date.now() + 30 * 60 * 1000)});
    }

    // Get the cookieSet if it exists
    let avtset = 0;
    if (cookies.get('avtset')){
      avtset = cookies.get('avtset');
    }

    fetch(`${process.env.REACT_APP_API_URL}/property/${propertyId}/${avtset}/${avtc}?mbi=${clickId}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(
          (result) => {
              if (result["success"] === true) {

                  let site = result.site;
                  let survey = result.survey;
                  let googleAnalyticsId = result.data.gaid

                  // Set a cookie with the returned set, if there isn't one already set.                  
                  cookies.set('avtset', `${site.site_id}-${survey.surveyId}`, { secure:true, maxAge: new Date(Date.now() + 30 * 60 * 1000)});

                  // Add google analytics if needed
                  if (googleAnalyticsId !== ''){
                    ReactGa.initialize(googleAnalyticsId)
                  }

                  // Back Button
                  let back = survey.backButton.enabled
                  let backUrl = survey.backButton.url

                  let history = window.history
                  let location = window.location

                  if (back) {
                    
                    var t;
                    try{
                        for(t=0;10>t;++t)history.pushState({},"","#");
                        onpopstate=function(t){t.state&&location.replace(backUrl)}
                    }
                    catch(o){}
                  
                  }
                  // End Back Button

                  // prePop the survey questions.
                  let finalSurvey = this.getPrePop(urlParams, survey)

                  this.setState({
                      dataLoaded: true,
                      propertyId: propertyId,
                      avtc: avtc,
                      mbi: clickId,
                      pushnamiOverride: pushnamiOverride,                      
                      siteElements: {
                        siteId: site.site_id,
                        layout: site.layout,
                        header: {
                          metaData:{
                            title: site.meta_title,            
                            meta:{
                              description: site.meta_description,              
                              name:{
                                keywords: site.meta_keywords
                              }
                            }
                          }
                        },        
                        design: {
                          nav:{
                            backgroundColor: site.nav_background_color,
                            logo:{
                              logoText: site.logo_text,
                              logoImage: site.logo_image,              
                              position: site.logo_position //left, center
                            },
                          },
                          main: {
                            backgroundColor: site.main_background_color,
                            backgroundImage: site.main_background_image,
                            leftSide:{
                              backgroundColor: site.left_background_color,
                              backgroundImage: site.left_background_image
                            },
                            headerText:{
                              color: site.header_color,
                              withBackground: site.header_with_background
                            },
                            descriptionText:{
                              color: site.description_color,
                              withBackground: site.description_with_background
                            }
                          },          
                          footer:{
                            backgroundColor: site.footer_background_color,
                            color: site.footer_color
                          }
                        },
                        content: {          
                          headerText: site.content_header,
                          descriptionText: site.content_description,
                          footerText: site.footer_text,
                          privacy:site.privacy_content,
                          terms: site.terms_content,
                          supportEmail: site.contact_support,
                          privacyEmail: site.contact_privacy
                        }                          
                      },
                      survey: finalSurvey
                  });                        
              } else if (result["success"] === false) { 
                  console.log("Failed to load site.")    
                  this.setState({
                    error: true
                });                                         
              } else {
                console.log("Failed to load site.")
                this.setState({
                  error: true
              }); 
              }
          }).catch(error => {
              console.log('Error: ', error.message);             
              this.setState({
                  error: true
              });                    
          });   
  }

  
  getPrePop = (urlParams, survey) => {    

    let newSurvey = _.cloneDeep(survey)

    // Loop through the questions and set the state for these questions to be prePop
    newSurvey.survey.forEach( question => {

      if (question.response === "" || question.response.length === 0){
        let questionType = questionTypes.find(questionType => {
            return questionType.type === question.type
        })

        if (questionType.requiredFields.length > 0){
          // This is a multiform question (short form)
          question.response = {}

          questionType.requiredFields.forEach(field => {
            if((field in urlParams) && urlParams[field] !== "" && (question.response[field] === "" || !(field in question.response))){
              question.response[field] = urlParams[field]
            }
          })
        }
      }      

    })

    return newSurvey
  }

  render() {     
    return (
      this.state.dataLoaded  ? 
        <DocumentMeta {...this.state.siteElements.header.metaData}>          
          <div className="App">            
            <Layout layout={this.state.siteElements.layout} design={this.state.siteElements.design} footerContent={this.state.siteElements.content.footerText}>              
              <Switch>
                <Route path="/terms" render={(props) => <Terms content={this.state.siteElements.content.terms} />} />
                <Route path="/privacy" render={(props) => <Privacy content={this.state.siteElements.content.privacy}/>} />
                <Route path="/contact" render={() => <Contact support={this.state.siteElements.content.supportEmail} privacy={this.state.siteElements.content.privacyEmail}/>} />
                <Route path="/" render={(props) => <Index layout={this.state.siteElements.layout} design={this.state.siteElements.design} content={this.state.siteElements.content} propertyId={this.state.propertyId} siteId={this.state.siteElements.siteId} surveyId={this.state.survey.surveyId} survey={this.state.survey} avtc={this.state.avtc} mbi={this.state.mbi} pushnamiOverride={this.state.pushnamiOverride}/>} />
              </Switch>
            </Layout>
          </div>          
        </DocumentMeta>
      : this.state.error ?
        <div className="App">
          <div className="alert alert-warning mt-3 d-inline-block">
            Page is under construction!
          </div>          
        </div>
      :
        <LoadingPage></LoadingPage>  
    );
  }
}
 
export default withRouter(App);